import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoginGoogle from './LoginGoogle';
import { Alert } from '@mui/material';
import logo from '../images/lunexcolorlogo.webp';

const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Section = styled.section`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const HeaderLogo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const Register = () => {
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSuccess = () => {
    setSuccess(true);
    navigate('/login');
  };

  return (
    <Container>
      <Section>
        <HeaderLogo src={logo} alt="Lunex Logo" />
        <h1>Register</h1>
        {errMsg && <Alert severity="error" style={{ marginTop: '10px' }}>{errMsg}</Alert>}
        <LoginGoogle 
          setErrMsg={setErrMsg}
          onSuccess={handleSuccess}
        />
      </Section>
    </Container>
  );
};

export default Register;
