import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const BASE_URL = 'https://lunexpowerbackend.ue.r.appspot.com';

// const BASE_URL = 'http://localhost:3500';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});