import {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { ChartsContainer, ChartsHeader, ChartsWrapper, customStyles, DateContainerFrom, DateContainerTo, DateHeader, DateWrapper, DealContainer, MessageContainer, MessageHeader, RevenueContainer } from './LeaderboardElements';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Sales = () => {
    const now = new Date();
    const [loading, setLoading] = useState(false);
    const [dealLoading, setDealLoading] = useState(false);
    const [reps, setReps] = useState();
    const [contracts, setContracts] = useState();
    const [dealReps, setDealReps] = useState();
    const [dealContracts, setDealContracts] = useState();
    const [dateRange, setDateRange] = useState([new Date(now.getFullYear(), now.getMonth(), 1), new Date()]);
    const [startDate, endDate] = dateRange;
    const [dealDateRange, setDealDateRange] = useState([new Date(now.getFullYear(), now.getMonth(), 1), new Date()]);
    const [dealStartDate, dealEndDate] = dealDateRange;
    const [selectedOption, setSelectedOption] = useState({label:"All", value:"All"});
    const [dealsSelectedOption, setDealsSelectedOption] = useState({label:"All", value:"All"});

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController(); 

        const getSalesRange = async () => {
            const newFromDate = startDate.getFullYear() + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (startDate.getDate())).slice(-2)
            const newToDate = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (endDate.getDate())).slice(-2);


            try {
                
                    setLoading(true);
                    const response = await axiosPrivate.get('/sales/range/'+newFromDate+'/'+newToDate, {
                        // signal: controller.signal
                    });
                    if(isMounted){
                        var dict = response.data;
                        var rep = [];
                        var contract = [];
                        for (let x in dict) {
                            if(process.env.NODE_ENV === 'dev'){
                                console.log(dict[x].rep + ": "+ dict[x].contractPrice)
                            }
                            console.log("Selected = ", selectedOption.value)
                            if(selectedOption.value === 'All'){
                                rep.push(dict[x].rep);
                                contract.push(dict[x].contractPrice)
                            }
                            if(dict[x].source === selectedOption.value){
                                rep.push(dict[x].rep);
                                contract.push(dict[x].contractPrice)
                            }
                        }

                        setLoading(false);
                        setReps(rep);
                        setContracts(contract);
                    }
                    
                    // controller.signal;
                

            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
            // finally {
            //     isMounted && setLoading(false)
            // }
        }

        getSalesRange();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [startDate,endDate,selectedOption,axiosPrivate,location,navigate]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getDealsRange = async () => {
            const newFromDate = dealStartDate.getFullYear() + '-' + ("0" + (dealStartDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (dealStartDate.getDate())).slice(-2)
            const newToDate = dealEndDate.getFullYear() + '-' + ("0" + (dealEndDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (dealEndDate.getDate())).slice(-2)

            try {
                
                    setDealLoading(true);
                    const response = await axiosPrivate.get('/deals/range/'+newFromDate+'/'+newToDate, {
                        // signal: controller.signal
                    });
                    if(isMounted){
                        var dict = response.data;
                        var rep = [];
                        var contract = [];
                        for (let x in dict) {
                            if(process.env.NODE_ENV === 'dev'){
                                console.log(dict[x].rep + ": "+ dict[x].contractPrice)
                            }
                            console.log("Deals Selected = ", dealsSelectedOption.value)
                            if(dealsSelectedOption.value === 'All'){
                                rep.push(dict[x].rep);
                                contract.push(dict[x].contractPrice)
                            }
                            if(dict[x].source === dealsSelectedOption.value){
                                rep.push(dict[x].rep);
                                contract.push(dict[x].contractPrice)
                            }
                        }
                        setDealReps(rep);
                        setDealContracts(contract);
                        setDealLoading(false);
                    }
                    
                

            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
            // finally {
            //     isMounted && setDealLoading(false)
            // }
        }

        getDealsRange();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [dealStartDate, dealEndDate, dealsSelectedOption,axiosPrivate,location,navigate]);

    // useEffect(() => {
    //     // console.log(`isLoading: ${isLoading}`)
    //     // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    // }, [loading,dealLoading])

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    autoSkip: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                font: {size: 30},
                text: 'Revenue Leaderboard',
            },
        },
    };
    const dealOptions = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    autoSkip: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                font: {size: 30},
                text: 'Deals Leaderboard',
            },
        },
    };
    const dataTotalHorBar = {
        labels: reps,
        datasets: [
          {
            label: 'Revenue $',
            backgroundColor: '#EC932F',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: contracts
          },
        ]
      };

      const dealTotalHorBar = {
        labels: dealReps,
        datasets: [
          {
            label: 'Deals',
            backgroundColor: '#EC932F',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: dealContracts
          },
        ]
      };

      const dropdownOptions = [
        { label: 'All', value: 'All' },
        { label: 'Outside (New England)', value: 'Outside - NE' },
        { label: 'Outside (Florida)', value: 'Outside - FL' },
        { label: 'Inside', value: 'Inside' },
        { label: 'Home Depot', value: 'Home Depot' },
        ];

    // const Dropdown = ({ label, defaultValue, options, onChange }) => {
    //     return (
    //         <label>
    //         {label}
    //         <Select 
    //             placeholder={label} 
    //             value={defaultValue} 
    //             onChange={onChange}
    //             options={options}
    //         />
    //         {/* </Select> */}
    //         </label>
    //     );
    // };

    
    
  return (
    <ChartsContainer>
        <ChartsHeader>Leaderboards</ChartsHeader>
        <ChartsWrapper>
            <RevenueContainer>
                <DateWrapper>
                    <DateContainerFrom>
                        <DateHeader>Select Range</DateHeader>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            showMonthPicker
                            style={{borderWidth:"4px !important"}}
                        />
                    </DateContainerFrom>
                    <DateContainerTo>
                        <DateHeader>Sale Type</DateHeader>
                        <Select
                            defaultValue={selectedOption}
                            options={dropdownOptions}
                            onChange={setSelectedOption}
                            styles={customStyles}
                        />
                    </DateContainerTo>
                </DateWrapper>
                
                
                {!loading
                    ? (<>
                        {reps?.length
                            ? (<Bar options={options} data={dataTotalHorBar}/>
                            ) : <MessageContainer>
                                    <MessageHeader>No sales during this period</MessageHeader>
                                </MessageContainer>
                        }
                    </>
                    ) : 
                        <MessageContainer>
                            <MessageHeader>Loading Sales Info...</MessageHeader>
                        </MessageContainer>
                }
                
            </RevenueContainer>
            <DealContainer>
                <DateWrapper>
                    <DateContainerFrom>
                        <DateHeader>Select Range</DateHeader>
                        <DatePicker
                            selectsRange={true}
                            startDate={dealStartDate}
                            endDate={dealEndDate}
                            onChange={(update) => {
                                setDealDateRange(update);
                            }}
                            showMonthPicker
                        />
                    </DateContainerFrom>
                    <DateContainerTo>
                        <DateHeader>Sale Type</DateHeader>
                        <Select
                            defaultValue={dealsSelectedOption}
                            options={dropdownOptions}
                            onChange={setDealsSelectedOption}
                            styles={customStyles}
                        />
                    </DateContainerTo>
                </DateWrapper>
                
                
                {!dealLoading
                    ? (<>
                        {dealReps?.length
                            ? (<Bar options={dealOptions} data={dealTotalHorBar}/>
                            ) : <MessageContainer>
                                    <MessageHeader>No Deals during this period</MessageHeader>
                                </MessageContainer>
                        }
                    </>
                    ) : 
                        <MessageContainer>
                            <MessageHeader>Loading Deal Info...</MessageHeader>
                        </MessageContainer>
                }
            </DealContainer>
        </ChartsWrapper> 
    </ChartsContainer>
  )
}

export default Sales