import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavbarWrapper = styled.div`
    display: flex;
    height: 100%;
    position: fixed;

    /* margin: 0; */
    /* background-color: #060b26; */
    /* height: 60px;
    display: flex;
    justify-content: start;
    align-items: center; */
`;

export const LunexHeaderLogo = styled.img`
  height: ${props => props.isMobile ? '50px' : '50px'};
  margin-left: ${props => !props.isMobile ? '10px' : '10px'};
  margin-top: ${props => props.isMobile ? '15px' : '15px'};
`;

export const LunexHeaderIcon = styled.img`
    
    height: 60px;
    margin-left: 15px;
    margin-top: 5px;

`;

export const NavbarLink = styled(Link)`
    /* margin-left: 10px;
    font-size: 2rem;
    background: none; */
`;

export const NavbarLogout = styled.li`
    /* margin-top: 9px; */
    /* background-color: #fff; */
    /* text-align: center; */
    color: #1BA8F1;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 10px;
    margin-top: 18px;
    /* width: 50px; */
    height: 50px;

    &:hover {
        color: #ffa500;
    }
`

export const NavMenu = styled.nav`
    background-color: #060b26;
    width: 200px;
    height: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
`;

export const NavMenuActive = styled.nav`
    background-color: #060b26;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: 350ms;
`;

export const NavMenuItems = styled.ul`
    width: 100%;
`;

export const NavbarToggle = styled.li`
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
`;

export const NavText = styled.li`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 8px;
    list-style: none;
    height: 60px;

    a {
        text-decoration: none;
        color: #f5f5f5;
        font-size: 18px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
    }
    a:hover {
        background-color: #1a83ff;
    }

`;

export const NavSpan = styled.span`
    margin-left: 16px;
`;