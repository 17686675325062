import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, TextField, Button, IconButton } from '@mui/material';
import contactsData from './ContactData';
import useAuth from '../hooks/useAuth';
import { Add, Delete } from '@mui/icons-material';

const ContactsTable = () => {
    const { auth } = useAuth();
    const [contacts, setContacts] = useState(contactsData);
    const [newContact, setNewContact] = useState({ name: '', title: '', email: '', cellPhone: '', officeExtension: '' });

    const isAdmin = auth?.roles?.includes(111);

    const handleInputChange = (id, field, value) => {
        const updatedContacts = contacts.map(contact => 
            contact.id === id ? { ...contact, [field]: value } : contact
        );
        setContacts(updatedContacts);
    };

    const handleNewContactChange = (field, value) => {
        setNewContact({ ...newContact, [field]: value });
    };

    const handleAddContact = () => {
        const newContactWithId = { ...newContact, id: contacts.length + 1 };
        setContacts([...contacts, newContactWithId]);
        setNewContact({ name: '', title: '', email: '', cellPhone: '', officeExtension: '' });
    };

    const handleDeleteContact = (id) => {
        setContacts(contacts.filter(contact => contact.id !== id));
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="contacts table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Cell Phone</TableCell>
                        <TableCell>Office Extension</TableCell>
                        {isAdmin && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contacts.map((contact) => (
                        <TableRow key={contact.id}>
                            <TableCell>
                                {isAdmin ? (
                                    <TextField
                                        value={contact.name}
                                        onChange={(e) => handleInputChange(contact.id, 'name', e.target.value)}
                                    />
                                ) : (
                                    contact.name
                                )}
                            </TableCell>
                            <TableCell>
                                {isAdmin ? (
                                    <TextField
                                        value={contact.title}
                                        onChange={(e) => handleInputChange(contact.id, 'title', e.target.value)}
                                    />
                                ) : (
                                    contact.title
                                )}
                            </TableCell>
                            <TableCell>
                                {isAdmin ? (
                                    <TextField
                                        value={contact.email}
                                        onChange={(e) => handleInputChange(contact.id, 'email', e.target.value)}
                                    />
                                ) : (
                                    <Link href={`mailto:${contact.email}`} underline="none">
                                        {contact.email}
                                    </Link>
                                )}
                            </TableCell>
                            <TableCell>
                                {isAdmin ? (
                                    <TextField
                                        value={contact.cellPhone}
                                        onChange={(e) => handleInputChange(contact.id, 'cellPhone', e.target.value)}
                                    />
                                ) : (
                                    <Link href={`tel:${contact.cellPhone}`} underline="none">
                                        {contact.cellPhone}
                                    </Link>
                                )}
                            </TableCell>
                            <TableCell>
                                {isAdmin ? (
                                    <TextField
                                        value={contact.officeExtension}
                                        onChange={(e) => handleInputChange(contact.id, 'officeExtension', e.target.value)}
                                    />
                                ) : (
                                    contact.officeExtension
                                )}
                            </TableCell>
                            {isAdmin && (
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteContact(contact.id)} color="error">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                    {isAdmin && (
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={newContact.name}
                                    onChange={(e) => handleNewContactChange('name', e.target.value)}
                                    placeholder="Name"
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={newContact.title}
                                    onChange={(e) => handleNewContactChange('title', e.target.value)}
                                    placeholder="Title"
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={newContact.email}
                                    onChange={(e) => handleNewContactChange('email', e.target.value)}
                                    placeholder="Email"
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={newContact.cellPhone}
                                    onChange={(e) => handleNewContactChange('cellPhone', e.target.value)}
                                    placeholder="Cell Phone"
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={newContact.officeExtension}
                                    onChange={(e) => handleNewContactChange('officeExtension', e.target.value)}
                                    placeholder="Office Extension"
                                />
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleAddContact} color="primary" startIcon={<Add />}>
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ContactsTable;
