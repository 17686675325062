import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styled from "styled-components";
// import tw from "twin.macro";

const SearchContainer = styled.div`
    /* margin-left: auto; */
    /* margin-right: 2px; */
    /* width: 80px; */
    display: flex; 
    /* margin-top: 1.5rem;  */
    /* margin-bottom: 1.5rem;  */
    align-items: center; 
`;

// const SearchText = styled.h2`
//     margin-right: 1.5rem; 
//     color: #4B5563; 
//     font-size: 1.25rem;
//     line-height: 1.75rem; 

// `;

const Input = styled.input`
  /* font-family: 'Avenir_Book'; */
  padding: 1rem; 
  font-size: 1REM;
  width: 250px;
  height: 2rem; 
  border-radius: 0.5rem; 
  border-width: 2px; 
  border-color: #ffa500; 
  border-style: solid; 
  outline: 0; 
`;

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <SearchContainer>
      {/* <SearchText>Search:</SearchText> */}
      <Input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search...`}
      />
    </SearchContainer>
  );
}