import React from 'react';
import styled from 'styled-components';
import VideoModal from '../components/VideoModal';
import auroraVideo from "../videos/AuroraTraining.mp4";
import HomeLink from '../components/HomeLink';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
`;

const Header = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const VideoLink = styled.button`
  background-color: #1BA8F1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;

  &:hover {
    background-color: #17a2b8;
  }
`;

const TrainingVideos = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  const openModal = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoUrl('');
  };

  return (
    <>
        <HomeLink />
        <Container>
        <Header>Training Videos</Header>
        <VideoLink onClick={() => openModal(auroraVideo)}>
            Aurora Design through Enerflo
        </VideoLink>
        {isModalOpen && <VideoModal videoSrc={videoUrl} onClose={closeModal} />}
        </Container>
    </>
  );
};

export default TrainingVideos;
