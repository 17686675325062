import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Leaderboard from './components/Leaderboards/Leaderboard';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import Welcome from './components/Welcome';
import MainTable from './components/MainTable/MainTable';
import CreateCustomer from './components/Customer/CreateCustomer';
import { useState } from 'react';
import EditCustomer from './components/Customer/EditCustomer';
import LinkPage from './pages/LinkPage/LinkPage';
import ContactPage from './pages/ContactPage';
import TrainingVideos from './pages/TrainingVideos';

const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {
  // const [showNav,setShowNav] = useState(true);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        {/* <Route path="linkpage" element={<LinkPage />} /> */}
        <Route path="unauthorized" element={<Unauthorized />} />
      {/* </Route>
      <Route path="/" element={<Layout {...showNav} />}> */}
        {/* <Route path="confirm/:confirmationCode" element={<Welcome />}/> */}

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin, ROLES.Editor]} />}>
            <Route path="/" element={<LinkPage />} />
            <Route path="contacts" element={<ContactPage />} />
            <Route path="training-videos" element={<TrainingVideos />} />
            {/* <Route path="/" element={<Home />} />
            <Route path="leaderboard" element={<Leaderboard />} />
            <Route path="customers" element={<MainTable />} />
            <Route path="createcustomer" element={<CreateCustomer />} />
            <Route path="editcustomer" element={<EditCustomer />} /> */}
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>      
    </Routes>
  );
}

export default App;