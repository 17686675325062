import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import { Typography, Paper } from '@mui/material';
import ProjectsTable from './ProjectsTable';
import dayjs from 'dayjs';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px;

  @media screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const Header = styled(Typography)`
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-bottom: 20px;
  color: #333 !important;
`;

const WidgetGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 1px;
`;

const Widget = styled(Paper)`
  flex: 1 1 calc(20% - 20px);
  max-width: 200px;
  min-width: 80px;
  padding: 5px;
  text-align: center;
  background-color: #1BA8F1 !important;
  color: white !important;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4) !important;
`;

const WidgetNumber = styled(Typography)`
  font-size: 2rem !important;
  font-weight: bold !important;
  text-shadow: 1px 1px 2px black !important;
`;

const WidgetTitle = styled(Typography)`
    color: black;
  font-size: .8rem !important;
  margin-top: 10px;
`;

const Project = () => {
  const { auth } = useAuth();
  const [projects, setProjects] = useState([]);
  const [dealsThisWeek, setDealsThisWeek] = useState(0);
  const [dealsThisMonth, setDealsThisMonth] = useState(0);
  const [dealsAllTime, setDealsAllTime] = useState(0);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.post('/projects/getProjectsByOwnerEmail', {
          email: auth.user,
          googleId: auth.googleId
        });
        const projectsData = response.data;

        // Truncate the 'Created at' field to ensure valid date parsing
        const sanitizedProjects = projectsData.map(project => {
          if (project['Created at']) {
            const truncatedDate = project['Created at'].substring(0, 19);
            project['Created at'] = truncatedDate;
          }
          return project;
        });

        setProjects(sanitizedProjects);

        // Calculate deals metrics
        const currentWeek = dayjs().week();
        const currentMonth = dayjs().month() + 1; // dayjs month is 0-indexed
        const currentYear = dayjs().year();

        const dealsThisWeekCount = sanitizedProjects.filter(
          project => dayjs(project['Created at']).week() === currentWeek
        ).length;

        const dealsThisMonthCount = sanitizedProjects.filter(
          project => dayjs(project['Created at']).month() + 1 === currentMonth &&
                     dayjs(project['Created at']).year() === currentYear
        ).length;

        setDealsThisWeek(dealsThisWeekCount);
        setDealsThisMonth(dealsThisMonthCount);
        setDealsAllTime(sanitizedProjects.length);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    if (auth.user && auth.googleId) {
      fetchProjects();
    }
  }, [auth.user, auth.googleId]);

  return (
    <Container>
      <Header>Deals</Header>
      <WidgetGrid>
        <Widget>
          <WidgetNumber>{dealsThisWeek}</WidgetNumber>
          <WidgetTitle>This Week</WidgetTitle>
        </Widget>
        <Widget>
          <WidgetNumber>{dealsThisMonth}</WidgetNumber>
          <WidgetTitle>This Month</WidgetTitle>
        </Widget>
        <Widget>
          <WidgetNumber>{dealsAllTime}</WidgetNumber>
          <WidgetTitle>All Time</WidgetTitle>
        </Widget>
      </WidgetGrid>
      <ProjectsTable projects={projects} />
    </Container>
  );
};

export default Project;

// Helper function to get the current week number using dayjs
dayjs.extend(require('dayjs/plugin/weekOfYear'));
