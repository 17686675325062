import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContactFormContainer = styled.div`

`

export const ContactFormWrapper = styled.div`
    /* grid-area: col2; */
    /* justify-items: center; */
    /* padding-top: 120px; */
    /* display: flex; */
    /* background-color: #fff; */
    margin-left: 200px;
    margin-right: auto;
    width: calc(100%-200px);
    
    /* @media screen and (max-width: 1050px) {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        height: 700px;
        padding-top: 20px;
    } */

    /* @media screen and (max-width: 768px) {
        width: 100%;
    } */

`;



export const ContactFormHeader = styled.h1`
    color: #fff;
    text-align: center;
    padding-top: 20px;
    /* margin-top: 40px; */
    margin-bottom: 20px;
`;

export const ContactInfo = styled.p`
    max-width: 600px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactInfoLink = styled.a`
    /* max-width: 600px; */
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    &:hover{
        color: #ffa500;
        transition: 0.3s ease-out;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactForm = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* max-width: 1000px; */
    /* width: calc(100%-200px); */
    /* margin-left: 100px; */
    margin-right: auto;
    /* background-color: #fff; */
    /* width: 100%; */

    @media screen and (max-width: 1250px) {
        grid-template-columns: 1fr;
    }
`;

export const ContactCol1 = styled.div`
    width: auto;
    max-width: 650px;
    margin-left: auto;
    margin-right: 1rem;
    @media screen and (max-width: 1250px) {
        margin-left: auto;
        margin-right: auto;

    }

`

export const ContactCol2 = styled.div`
    width: auto;
    max-width: 650px;
    /* margin-left: 1rem; */
    margin-right: 1rem;
    @media screen and (max-width: 1250px) {
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
    }
`

export const FormRow = styled.div`
    display: grid;
    width: auto;
    /* justify-items: center; */
    /* margin-left: auto;
    margin-right: auto; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    grid-template-columns: 1fr 1fr;
    margin: 30px;
    margin-top: 5px;
    gap: 10px;

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }


`;

export const NoteFormRow = styled.div`
    width: 80%;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center; */

`;
export const FormMessageRow = styled.div`
    display: grid;
    /* margin-left: 0;
    margin-right: 0; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    grid-template-columns: 1fr;
    margin: 50px;
    gap: 40px;
`;

export const ContactFormFields = styled.div`

`;

export const ExpandableComponents = styled.div`
    /* width: calc(100%-200px); */
    background-color: rgba(0,0,0,.2);
    /* margin: 1rem; */
    /* margin-left: auto;
    margin-right: auto; */
    /* margin: auto; */
    padding-bottom: 1rem;
    border-radius: 50px;
    @media screen and (max-width: 1250px) {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ContactExtraFormFields = styled.div`
    width: 80%;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* text-align: center; */
    justify-content: space-evenly;
    /* justify-content: space-between; */
    margin-left: auto;
    margin-right: auto;
    /* background-color: #fff; */
    
`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 200px; */
    /* gap: 30px; */
    /* background-color: red; */
    @media screen and (max-width: 480px) {
        /* gap: 20px; */
    }
`;

export const ContactFormInfoTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    font-family: 'Avenir_Book';
    
    @media screen and (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const ContactFormExtraInfoTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    font-family: 'Avenir_Book';
    align-items: center;
    /* margin: auto; */
    margin-top: auto;
    margin-bottom: auto;
    @media screen and (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const ContactFormInfoInput = styled.input`
    border-radius: '10px';
    border-color: 'black',;
    border-width: '3px',;
    /* height: 45px; */
    
`;

export const ContactFormExtraInfoInput = styled.input`
    border-radius: '10px';
    /* margin: 20px; */
    border-color: 'black',
    border-width: '3px',
    height: 60px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

export const Datepicker = styled(ReactDatePicker)`
    border-radius: '10px';
    border-color: 'black',
    border-width: '3px',
    height: 45px;
    width: 100%;
    /* width: 1fr; */
`;

export const ApptDateTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    /* background-color: #000; */
    /* z-index: 10; */
`;

export const ButtonContainer = styled.div`
    /* margin: auto; */
    /* align-items: center; */
    /* vertical-align: middle; */
    /* height: auto; */
    width: 200px;
    margin-left: auto;
    margin-right: auto;
`;

export const ApptButtonContainer = styled.div`
    /* margin: auto; */
    /* align-items: center; */
    width: 200px;
    margin: 30px;
    /* margin-left: auto;
    margin-right: auto; */
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const ContactLink = styled(Link)`

`

export const SubmitErrorMessage = styled.div.attrs((props: {color: string}) => props)`
    color: ${(props) => props.color};
    font-size: 25px;
    padding: 10px;
    margin: auto;
    text-align: center;
    align-items: center;
    width: 400px;
`;

export const RequiredErrorMessage = styled.div`
    color: #000;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    text-align: center;
    font-family: 'Avenir_Book';
`;

export const ErrorMessage = styled.div`
    color: red;
    font-family: 'Avenir_Book';
    margin-top: -20px;
    margin-bottom: -30px;
`;

export const customStyles = {
    option: provided => ({
      ...provided,
      margin: '0px',
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      borderRadius: '12px',
      borderColor: '#ffa500',
      borderWidth: '2px',
    //   height: '30px',
    //   minHeight: '30px'
    }),
    singleValue: (provided) => ({
      ...provided,
    //   margin: '0px',
    //   paddingBottom:'5px',
      color: 'black'
    }),
    valueContainer: (provided) => ({
        ...provided,
        // minHeight: '30px',
        height: '45px',
    }) 
};