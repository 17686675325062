import { Link } from "react-router-dom";
import styled from "styled-components";
import * as AiIcons from 'react-icons/ai';
import * as FiIcons from 'react-icons/fi';
import * as GrIcons from 'react-icons/gr';

export const TableContainer = styled.div`
    margin-left: 200px;
    margin-top: 20px;
    width: calc(100% - 200px);
    height: calc(100% - 60px);
    /* background-color: #fff; */
`;

export const TableHeaderContainer = styled.div`
    height: 60px;
`

export const TableHeader = styled.h2`
    text-align: center;
`

export const FilterContainer = styled.div`
    height: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px;
    /* vertical-align: center; */
    /* margin-top: auto;
    margin-bottom: auto; */
    /* background-color: #000; */
`;

export const DealLink = styled(Link)`
    
`

export const DealButton = styled.button`
    margin-top: 5px;
    margin-left: 5px;
    /* margin-bottom: 5px; */
    /* padding-top: 10px; */
    background-color: #1BA8F1;
    height: 50px;
    border-color:#fff;
    text-align: center;
    line-height: 28px;
    /* border-width: 3px; */
    /* border-radius: 2px; */
    color: #fff;
    /* vertical-align: center; */
    /* text-align: center; */
    /* justify-content: center; */
    &:hover {
        background-color: #ffa500;
    }
`

export const Table = styled.table`
    border-collapse: collapse;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* width: 800px; */

`;

export const Thead = styled.thead`
`;

export const TR = styled.tr`
`;

export const TH = styled.th`
    border: 1px solid #ffffff;
    color: black;
    text-align: left;
    padding: 8px;
    font-size: 18px;
    text-transform: capitalize;
    background-color: rgb(117, 201, 250);
`;

export const TD = styled.td`
    border: 1px solid #ffffff;
    /* height: 20px; */
    color: black;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    background-color: rgb(205, 235, 253);
`;

export const Tbody = styled.tbody`
`;

export const ModifyFieldWrapper = styled.div`
    /* background-color: #fff; */
    margin-left: 0;
    margin-right: 0;
    text-align: center;
`

export const PaginationWrapper = styled.div`
    background-color: rgb(205, 235, 253);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-evenly;
    align-items: center;
    /* align-items: flex-end; */
    padding: 5px;
    /* padding-top:5px; */
    /* padding-bottom: 5px; */
    vertical-align: center;
`

export const FilterIcon = styled(FiIcons.FiFilter)`
    color: #fff;
    /* align-items: center; */
    font-size:40px;
    &:hover {
        color: #ffa500;
    }
`

export const PreviousIcon = styled(GrIcons.GrCaretPrevious)`
    /* font-size: 200%; */
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    color: #1BA8F1;
    &:hover {
        color: #ffa500;
    }

`

export const NextIcon = styled(GrIcons.GrCaretNext)`
    /* font-size: 200%; */
    height: 25px;
    color: #1BA8F1; 
    &:hover {
        color: #ffa500;
    }
`

export const EditIcon = styled(AiIcons.AiFillEdit)`
    color: #1BA8F1;
    &:hover {
        color: #ffa500;
    }
`

export const DeleteIcon = styled(AiIcons.AiFillDelete)`
    /* height: 50px; */
    color: #1BA8F1;

    &:hover {
        color: red;
    }
`

export const DropdownFilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin-right: 5px; */
    margin-top: 5px;
    align-items: center;
    /* margin: auto; */
    /* margin-top: auto;
    margin-bottom: auto; */
    /* height: 100%; */
    /* justify-content: space-evenly; */
    gap:5px;
    /* align-items: center; */
    margin-left: auto;
    /* align-self: center; */
    /* margin-left: 20px; */
`

export const PaginationPageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:40px;
`

export const PaginationPageSizeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`

export const PaginationPageSizeHeader = styled.p`
    color: black;
    font-size: 18px;
`

export const customStylesPageSize = {
    option: provided => ({
      ...provided,
      margin: '0px',
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      borderRadius: '12px',
      borderColor: '#ffa500',
      borderWidth: '2px',
    //   height: '30px',
    //   minHeight: '30px'
    }),
    singleValue: (provided) => ({
      ...provided,
    //   margin: '0px',
    //   paddingBottom:'5px',
      color: 'black',
        marginBottom: '5px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        // minHeight: '30px',
        height: '35px',
    }),
    placeholder: (provided) => ({
        ...provided,
        marginBottom: '5px',
        color: 'black',
    })

};