import { Outlet } from "react-router-dom"

const Layout = ({showNav}) => {
    return (
        <>
            <Outlet />
        </>
        
    )
}

export default Layout
