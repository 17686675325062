import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import Project from '../../components/Project';
import PhoneIcon from '@mui/icons-material/Phone';

const Container = styled.div`
  color: #333;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;

  @media screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const WidgetGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Widget = styled.div`
  background-color: #1BA8F1;
  padding: 20px;
  border-radius: 8px;
  color: white;
  flex: 1 1 calc(30% - 20px);
  min-width: 220px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
`;

const WidgetTitle = styled.h2`
  color: white;
  margin-bottom: 10px;
  font-size: 1.4rem;
  text-shadow: 1px 1px 2px black;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  margin: 10px 0;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  text-shadow: 1px 1px 2px black;
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  flex-grow: 1;
  &:hover {
    text-decoration: underline;
  }
`;

const PhoneLink = styled.a`
  color: white;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  text-shadow: 1px 1px 2px black !important;
`;

const PhoneIconStyled = styled(PhoneIcon)`
  height: 15px !important;
  
`;


const InternalLink = styled(Link)`
  color: white;
  text-decoration: none;
  flex-grow: 1;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkPage = () => {
    return (
    <Container>
      <Wrapper>
        <WidgetGrid>
          <Widget>
            <WidgetTitle>Resources</WidgetTitle>
            <LinkList>
              <LinkItem><ExternalLink href="https://enerflo.io" target="_blank" rel="noopener noreferrer">Enerflo</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://app.coperniq.io" target="_blank" rel="noopener noreferrer">Coperniq</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://www.lunexpower.com/onboarding" target="_blank" rel="noopener noreferrer">Onboarding</ExternalLink></LinkItem>
              <LinkItem><InternalLink to="/contacts">Lunex Staff</InternalLink></LinkItem>
              <LinkItem><InternalLink to="/training-videos">Training Videos</InternalLink></LinkItem>
            </LinkList>
          </Widget>
          <Widget>
            <WidgetTitle>Reference Sheets</WidgetTitle>
            <LinkList>
              <LinkItem><ExternalLink href="https://docs.google.com/spreadsheets/d/1QQZwPFp65Yyquxq3CugsMZxlpfZEEOnJ5PL4IEypP-U/edit?gid=535596402#gid=535596402" target="_blank" rel="noopener noreferrer">Adders</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://docs.google.com/spreadsheets/d/1gmgh_jgzCAFuK2y7-9P78wXnqGEWmBltd2IlS87FeEQ/edit?gid=1714426682#gid=1714426682" target="_blank" rel="noopener noreferrer">Dealer Rates</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://lunexpower.enerflo.io/equipment/modules?page=1&pageSize=25&status=ACTIVE&orderBy=displayName%3Aasc" target="_blank" rel="noopener noreferrer">Modules</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://lunexpower.enerflo.io/equipment/inverters?page=1&pageSize=25&status=ACTIVE&orderBy=displayName%3Aasc" target="_blank" rel="noopener noreferrer">Inverters</ExternalLink></LinkItem>
              <LinkItem><ExternalLink href="https://lunexpower.enerflo.io/equipment/batteries?page=1&pageSize=25&status=ACTIVE&orderBy=displayName%3Aasc" target="_blank" rel="noopener noreferrer">Batteries</ExternalLink></LinkItem>
            </LinkList>
          </Widget>
          <Widget>
            <WidgetTitle>Lenders</WidgetTitle>
            <LinkList>
              <LinkItem>
                <ExternalLink href="https://origin.goodleap.com/login" target="_blank" rel="noopener noreferrer">Goodleap</ExternalLink>
                <PhoneLink href="tel:+18449100111"><PhoneIconStyled /></PhoneLink>
              </LinkItem>
              <LinkItem>
                <ExternalLink href="https://partner.dividendfinance.com/" target="_blank" rel="noopener noreferrer">Dividend</ExternalLink>
                <PhoneLink href="tel:+18445727218"><PhoneIconStyled /></PhoneLink>
              </LinkItem>
              <LinkItem>
                <ExternalLink href="https://palmetto.finance/accounts" target="_blank" rel="noopener noreferrer">Lightreach</ExternalLink>
              </LinkItem>
              <LinkItem>
                <ExternalLink href="https://partner.enfin.com/pi/s/login/" target="_blank" rel="noopener noreferrer">EnFin</ExternalLink>
                <PhoneLink href="tel:+18335863675"><PhoneIconStyled /></PhoneLink>
              </LinkItem>
              <LinkItem>
                <ExternalLink href="https://slfportal.com/orange/#/login" target="_blank" rel="noopener noreferrer">Sunlight</ExternalLink>
                <PhoneLink href="tel:+18888503359"><PhoneIconStyled /></PhoneLink>
              </LinkItem>
              <LinkItem>
                <ExternalLink href="https://portal.joinmosaic.com/pipeline" target="_blank" rel="noopener noreferrer">Mosaic</ExternalLink>
                <PhoneLink href="tel:+18557467849"><PhoneIconStyled /></PhoneLink>
              </LinkItem>
            </LinkList>
          </Widget>
        </WidgetGrid>
        <Project />
      </Wrapper>
    </Container>
  );
};

export default LinkPage;
