import styled from "styled-components";
// import * as AiIcons from 'react-icons/ai';
// import * as GrIcons from 'react-icons/gr';
import * as FiIcons from 'react-icons/fi';

export const TableContainer = styled.div`
    /* margin-left: 200px;
    margin-top: 20px;
    width: calc(100% - 200px);
    height: calc(100% - 60px); */
    /* background-color: #fff; */
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin: auto;

`;

export const Thead = styled.thead`
`;

export const TR = styled.tr`

`;

export const TH = styled.th`
    border: 1px solid #ffffff;
    color: black;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    background-color: rgb(117, 201, 250);
`;

export const TD = styled.td`
    border: 1px solid #ffffff;
    height: 20px;
    color: black;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    background-color: rgb(205, 235, 253);
`;

export const Tbody = styled.tbody`

`;

export const ConfirmationContainer = styled.div`
    background: deepskyblue;
    width: 400px;
    height: 200px;
    text-align: center;
    color: white;
`;

export const ConfirmationButtons = styled.button`
    background-color: transparent;
    border: 1px solid white;
    width: 150px;
    height: 2rem;
    margin: 1rem;
    color: white;
`

export const PaginationWrapper = styled.div`
    background-color: rgb(205, 235, 253);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-evenly;
    /* align-items: flex-end; */

    padding-top:5px;
    padding-bottom: 5px;
    vertical-align: center;
`

export const PreviousChapterIcon = styled(FiIcons.FiChevronsLeft)`
    /* font-size: 200%; */
    height: 25px;
    /* fill: red; */
    /* background-color: red; */
    color: ${(props) => (props.disabled ? `#808080` : `#1BA8F1`)};
    &:hover {
        color: ${(props) => (props.disabled ? `#808080` : `#ffa500`)};
    }

`

export const PreviousIcon = styled(FiIcons.FiChevronLeft)`
    /* font-size: 200%; */
    height: 25px;
    color: ${(props) => (props.disabled ? `#808080` : `#1BA8F1`)};
    &:hover {
        color: ${(props) => (props.disabled ? `#808080` : `#ffa500`)};
    }

`

export const NextIcon = styled(FiIcons.FiChevronRight)`
    /* font-size: 200%; */
    height: 25px;
    color: ${(props) => (props.disabled ? `#808080` : `#1BA8F1`)};
    &:hover {
        color: ${(props) => (props.disabled ? `#808080` : `#ffa500`)};
    }
`

export const NextChapterIcon = styled(FiIcons.FiChevronsRight)`
    /* font-size: 200%; */
    height: 25px;
    color: ${(props) => (props.disabled ? `#808080` : `#1BA8F1`)};
    &:hover {
        color: ${(props) => (props.disabled ? `#808080` : `#ffa500`)};
    }
`

export const PageNumber = styled.span`
    font-size: 18px;
    color: #000;
`

export const NoteFormRow = styled.div`
    width: 100%;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center; */

`;

export const NoteFormInfoInput = styled.input`
    border-radius: '10px';
    /* margin: 20px; */
    border-color: 'black',
    border-width: '3px',
    height: 60px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

export const ButtonContainer = styled.div`
    /* margin: auto; */
    /* align-items: center; */
    width: 200px;
    margin: 30px;
    /* margin-left: auto;
    margin-right: auto; */
`;
