import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const LoginGoogle = ({ errMsg, setErrMsg }) => {
  const { setAuth } = useAuth();
  const LOGIN_URL = '/auth';

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleGoogleLogin = async (googleUser) => {
    try {
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ id_token: googleUser.credential }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const user = response?.data?.email;
      const accessToken = response?.data?.accessToken;
      const googleId = response?.data?.googleId;
      const roles = response?.data?.roles;
      setAuth({ user, googleId, roles, accessToken });
      localStorage.setItem("user", JSON.stringify({ user, googleId, roles, accessToken }));
      localStorage.setItem("persist", true);
      navigate(from, { replace: true });
    } catch (err) {
        console.log("error", err.data)
      if (err.response?.data.message === "Email must be an @lunexpower.com address") {
        setErrMsg("Email must be an @lunexpower.com address");
      } else if (err.response?.data?.error) {
        setErrMsg(err.response.data.error);
      } else {
        console.log("error end", err.response.data)
        setErrMsg("Error encountered");
      }
    }
  };

  return (
    <div>
      {errMsg && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      )}
      <GoogleLogin onSuccess={handleGoogleLogin} useOneTap auto_select />
    </div>
  );
};

export default LoginGoogle;
