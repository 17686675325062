import styled, { css, createGlobalStyle } from "styled-components";

export const ChartsContainer = styled.div`
    width: calc(100%-200px);
    height: calc(100%-60px);
    margin-left: 200px;
    margin-top: 20px;
    /* margin-top: auto;
    margin-right: 0; */
    vertical-align: top;
    position: relative;
    /* align-items: top; */
    /* background-color: #fff; */
`;

export const ChartsHeader = styled.h2`
    text-align: center;
`

export const ChartsWrapper = styled.div`
    /* background-color: #fff; */
    /* vertical-align: top; */
    /* max-width: calc(100%-200px); */
    /* position: absolute; */
    vertical-align: top;
    display: grid;
    grid-column-gap: 50px;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: "revenue deal";
    margin-left:auto;
    margin-right: auto;
    /* width: 80vw; */
    /* margin-top: 0; */
    /* margin-left: 20px; */
    padding: 20px;
    /* padding-bottom: 55px; */

    @media screen and (max-width: 1653px) {
        /* padding: 5px; */
        grid-gap: 35px;
        /* padding-bottom: 0px; */
        grid-template-rows: repeat(auto, 1fr);
        grid-template-areas: "revenue" "deal";

    }

`;

export const RevenueContainer = styled.div`
    background-color: #fff;
    grid-area: revenue;
    height: 80vh;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    /* width: 98vw; */
    padding-bottom: 100px;

    @media screen and (max-width: 768px) {
        width: 95vw;
        height: 95vh;
        padding-bottom: 180px;
    }
`;

export const DealContainer = styled.div`
    background-color: #fff;
    grid-area: deal;
    height: 80vh;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    padding-bottom: 100px;

    @media screen and (max-width: 768px) {
        width: 95vw;
        height: 95vh;
        padding-bottom: 180px;
    }
`;

export const DateWrapper = styled.div`
    display: grid;
    padding: 10px;
    grid-column-gap: 20px;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: "from to";
    /* margin-left: 40px; */
    /* width: 95vw; */

    @media screen and (max-width: 768px) {
        /* padding: 2px; */
        /* grid-gap: 15px; */
        grid-template-rows: repeat(auto, 1fr);
        grid-template-areas: "from" "to";

    }
`;

export const DateContainerFrom = styled.div`
    grid-area: from;
    /* margin-left: 10px; */
    margin-left: auto;
    margin-right: auto;

    /* @media screen and (max-width: 768px) {
        width: 100px;

    } */
`;

export const DateContainerTo = styled.div`
    grid-area: to;
    width: 280px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px) {
        /* width: 280px;
        margin-left: auto;
        margin-right: auto; */
        /* border-radius: 30px; */

    }
`;

export const DateHeader = styled.h3`
    text-align: center;
    color: #000;

    @media screen and (max-width: 768px) {
        font-size: 24px;

    }
`;

export const DatePickerWrapperStyles = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
  }
`;

export const MessageContainer = styled.div`

`;
export const MessageHeader = styled.h1`
    padding-top: 200px;
    /* padding-bottom: 200px; */
    text-align: center;
    color: #000;
`;

export const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      borderRadius: '10px',
      borderColor: 'black',
      borderWidth: '2px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    // control: styles => ({
    //     ...styles,
    //     color: 'black',
    //     // borderRadius: '10px !important',
    // })
  };