import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import useLocalStorage from "../hooks/useLocalStorage";
import Navbar from "./Navbar/Navbar";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [persist] = useLocalStorage('persist', false);

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            } finally {
                if (isMounted) setIsLoading(false);
            }
        };

        // Only attempt to refresh if no accessToken and persistence is enabled
        if (!auth?.accessToken && persist) {
            verifyRefreshToken();
        } else {
            setIsLoading(false);
        }

        return () => (isMounted = false);
    }, [auth?.accessToken, persist, refresh]);

    return (
        <>
            {!persist ? (
                <>
                    <Navbar />
                    <Outlet />
                </>
            ) : isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Navbar />
                    <Outlet />
                </>
            )}
        </>
    );
}

export default PersistLogin