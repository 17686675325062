import React from 'react';
import ContactsTable from '../components/Contacts';
import HomeLink from '../components/HomeLink';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const ContactPage = () => {
  return (
    <Container>
      <HomeLink />
      <Title>Contacts List</Title>
      <ContactsTable />
    </Container>
  );
};

export default ContactPage;
