// src/components/HomeLink.js
import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: #1BA8F1;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: #0e73a7;
    text-decoration: underline;
  }

  & svg {
    margin-right: 8px;
    font-size: 1.5rem;
  }
`;

const HomeLink = ({ to = "/" }) => {
  return (
    <StyledLink to={to}>
      <ArrowBackIcon />
      Home
    </StyledLink>
  );
};

export default HomeLink;
