import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LunexHeaderLogo } from './NavbarElements';
import logo from '../../images/lunexcolorlogo.webp';
import useLogout from '../../hooks/useLogout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

const Navbar = ({ isNavbarCollapsed, toggleNavbar }) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: '#fff' }}>
        <Toolbar sx={{ alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to='/'>
              <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'} />
            </Link>
          </Box>
          <Button
            onClick={signOut}
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 'auto' }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
