import axios from "../api/axios";
import useAuth from "./useAuth";
import { googleLogout } from '@react-oauth/google';

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        localStorage.removeItem("user");
        localStorage.setItem("persist", false);
        try {
            await axios('/logout', {
                withCredentials: true
            });
            googleLogout();
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout