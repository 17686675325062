import styled from "styled-components";
import logo_img from '../images/lunexlogo.webp';

export const LunexHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100px;
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    text-align: center;


`;

export const LunexHeaderLogo = styled.img`
    
    /* width: 240px; */
    height: 80px;
    display: flex;
    align-items: center;
    justify-self: center;
    margin-top: 9px;
    margin-left: auto;
    margin-right: auto;


    @media screen and (max-width: 767px) {
        /* height: 80px;  */
        /* background-size: 80%; */

    }

`;

