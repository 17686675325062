// src/components/VideoModal.js

import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const VideoContainer = styled.div`
  width: 95%;
  height: 95%;
  background: #000;
  position: relative;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
`;

const VideoModal = ({ videoSrc, onClose }) => {
  return (
    <Overlay>
      <VideoContainer>
        <VideoPlayer controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoPlayer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </VideoContainer>
    </Overlay>
  );
};

export default VideoModal;
