// src/data/contactsData.js

const contactsData = [
    {
        id: 1,
        name: 'Dan Gawrych',
        title: 'CEO',
        email: 'dan@lunexpower.com',
        cellPhone: '+18136385178'
    },
    {
        id: 2,
        name: 'Schuyler Swartz',
        title: 'VP of Sales',
        email: 'schuyler@lunexpower.com',
        cellPhone: '+18137244071'
    },
    {
        id: 3,
        name: 'Matt Rambo',
        title: 'CFO',
        email: 'accountant@lunexpower.com',
        cellPhone: '+16109847198'
    },
    {
        id: 4,
        name: 'Mitchel Winslow',
        title: 'Sales Manager',
        email: 'mitchel@lunexpower.com',
        cellPhone: '+18634148818'
    },
    {
        id: 5,
        name: 'Ryan Swartz',
        title: 'Sales',
        email: 'rswartz@lunexpower.com',
        cellPhone: '+17273131364'
    },
    {
        id: 6,
        name: 'David Sipos',
        title: 'CTO',
        email: 'david@lunexpower.com',
        cellPhone: '+18322283136'
    },
];

export default contactsData;
