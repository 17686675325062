// import { useRef, useState, useEffect } from 'react';
// import useAuth from '../hooks/useAuth';
// import { Link, useNavigate, useLocation } from 'react-router-dom';

// import axios from '../api/axios';
// const LOGIN_URL = '/auth';

// const Login = () => {
//     const { setAuth, persist, setPersist } = useAuth();

//     const navigate = useNavigate();
//     const location = useLocation();
//     const from = location.state?.from?.pathname || "/";

//     const userRef = useRef();
//     const errRef = useRef();

//     const [user, setUser] = useState('');
//     const [pwd, setPwd] = useState('');
//     const [errMsg, setErrMsg] = useState('');

//     useEffect(() => {
//         userRef.current.focus();
//     }, [])

//     useEffect(() => {
//         setErrMsg('');
//     }, [user, pwd])

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(LOGIN_URL,
//                 JSON.stringify({ user, pwd }),
//                 {
//                     headers: { 'Content-Type': 'application/json' },
//                     withCredentials: true
//                 }
//             );
//             // console.log(JSON.stringify(response?.data));
//             //console.log(JSON.stringify(response));
//             const accessToken = response?.data?.accessToken;
//             const roles = response?.data?.roles;
//             setAuth({ user, pwd, roles, accessToken });
//             setUser('');
//             setPwd('');
//             navigate(from, { replace: true });
//         } catch (err) {
//             if (!err?.response) {
//                 setErrMsg('No Server Response');
//             } else if (err.response?.status === 400) {
//                 setErrMsg('Missing Username or Password');
//             } else if (err.response?.status === 401) {
//                 setErrMsg('Unauthorized');
//             } else {
//                 setErrMsg('Login Failed');
//             }
//             errRef.current.focus();
//         }
//     }

//     const togglePersist = () => {
//         setPersist(prev => !prev);
//     }

//     useEffect(() => {
//         localStorage.setItem("persist", persist);
//     }, [persist])

//     return (

//         <section>
//             <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
//             <h1>Sign In</h1>
//             <form onSubmit={handleSubmit}>
//                 <label htmlFor="username">Email:</label>
//                 <input
//                     type="text"
//                     id="username"
//                     ref={userRef}
//                     autoComplete="off"
//                     onChange={(e) => setUser(e.target.value)}
//                     value={user}
//                     required
//                 />

//                 <label htmlFor="password">Password:</label>
//                 <input
//                     type="password"
//                     id="password"
//                     onChange={(e) => setPwd(e.target.value)}
//                     value={pwd}
//                     required
//                 />
//                 <button>Sign In</button>
//                 <div className="persistCheck">
//                     <input
//                         type="checkbox"
//                         id="persist"
//                         onChange={togglePersist}
//                         checked={persist}
//                     />
//                     <label htmlFor="persist">Trust This Device</label>
//                 </div>
//             </form>
//             <p>
//                 Need an Account?<br />
//                 <span className="line">
//                     <Link to="/register">Sign Up</Link>
//                 </span>
//             </p>
//         </section>

//     )
// }

// export default Login

// import { useRef, useState, useEffect } from 'react';
// import useAuth from '../hooks/useAuth';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import useInput from '../hooks/useInput';
// import useToggle from '../hooks/useToggle';
// import logo from '../images/lunexcolorlogo.webp'

// import axios from '../api/axios';
// import { LunexHeaderLogo } from './LayoutElements';
// const LOGIN_URL = '/auth';

// const Login = () => {
//     const { setAuth } = useAuth();

//     const navigate = useNavigate();
//     const location = useLocation();
//     const from = location.state?.from?.pathname || "/";

//     const userRef = useRef();
//     const errRef = useRef();

//     const [user, resetUser, userAttribs] = useInput('user', '')
//     const [pwd, setPwd] = useState('');
//     const [errMsg, setErrMsg] = useState('');
//     const [check, toggleCheck] = useToggle('persist', false);

//     useEffect(() => {
//         userRef.current.focus();
//     }, [])

//     useEffect(() => {
//         setErrMsg('');
//     }, [user, pwd])

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(LOGIN_URL,
//                 JSON.stringify({ user, pwd }),
//                 {
//                     headers: { 'Content-Type': 'application/json' },
//                     withCredentials: true
//                 }
//             );
//             console.log("resp ",response);
//             const accessToken = response?.data?.accessToken;
//             const roles = response?.data?.roles;
//             setAuth({ user, pwd, roles, accessToken });
//             resetUser();
//             setPwd('');
//             navigate(from, { replace: true });
//         } catch (err) {
//             console.log("err ",err);
//             if (!err?.response) {
//                 setErrMsg('No Server Response');
//             } else if (err.response?.status === 400) {
//                 setErrMsg('Missing Username or Password');
//             } else if (err.response?.status === 401) {
//                 setErrMsg('Unauthorized');
//             } else {
//                 setErrMsg('Login Failed');
//             }
//             errRef.current.focus();
//         }
//     }

//     return (
//         <main className="App">
//             <section style={{ marginLeft:`1px` }}>
//                 <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/>
//                 <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
//                 <h1>Sign In</h1>
//                 <form onSubmit={handleSubmit}>
//                     <label htmlFor="username">Username:</label>
//                     <input
//                         type="text"
//                         id="username"
//                         ref={userRef}
//                         autoComplete="off"
//                         {...userAttribs}
//                         required
//                     />

//                     <label htmlFor="password">Password:</label>
//                     <input
//                         type="password"
//                         id="password"
//                         onChange={(e) => setPwd(e.target.value)}
//                         value={pwd}
//                         required
//                     />
//                     <button>Sign In</button>
//                     <div className="persistCheck">
//                         <input
//                             type="checkbox"
//                             id="persist"
//                             onChange={toggleCheck}
//                             checked={check}
//                         />
//                         <label htmlFor="persist">Trust This Device</label>
//                     </div>
//                 </form>
//                 <p>
//                     Need an Account?<br />
//                     <span className="line">
//                         <Link to="/register">Sign Up</Link>
//                     </span>
//                 </p>
//             </section>
//         </main>

//     )
// }

// export default Login


import { useRef, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';
import logo from '../images/lunexcolorlogo.webp'
import axios from '../api/axios';
import { LunexHeaderLogo } from './LayoutElements';
import LoginGoogle from './LoginGoogle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';

const LOGIN_URL = '/auth';

const theme = createTheme();

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [user, resetUser, userAttribs] = useInput('user', '')
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    // useEffect(() => {
    //     userRef.current.focus();
    // }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email: user, password: data.get('password') }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log("resp ",response);
            const user = response?.data?.email;
            const googleId = response?.data?.googleId;
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            console.log("roles ", roles);
            setAuth({ user, googleId, roles, accessToken });

            localStorage.setItem("user", JSON.stringify({ user, googleId, roles, accessToken }));
            localStorage.setItem("persist", true);
            resetUser();
            navigate(from, { replace: true });
        } catch (err) {
            console.log("err ",err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>            
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/>
                <Typography component="h1" variant="h4">
                    Sales Portal
                </Typography>
                <br />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {errMsg && <Alert severity="error" style={{marginTop: '10px'}}>{errMsg}</Alert>}
                <br />
                <LoginGoogle 
                    errMesg={errMsg}
                    setErrMsg={setErrMsg}
                />
                
                </Box>
            </Container>
        </ThemeProvider>

    )
}

export default Login