import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TextField, InputAdornment, IconButton, Typography, Tooltip, Link as MuiLink } from '@mui/material';
import { Search, Sort, ArrowBack, ArrowForward, Clear } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Bolt } from '@mui/icons-material'; // Import the electric bolt icon

const Container = styled.div`
  width: 95%;
  height: auto;
  margin: 20px auto;

  @media (max-width: 600px) {
    width: 98%;
  }
`;

const FilterBar = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  gap: 10px;
  
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterItem = styled.div`
  max-width: 200px;
  min-width: 200px;
  width: 100%;
`;

const SearchInput = styled(TextField)`
  max-width: 200px;
  min-width: 200px;
  width: 100%;
`;

const DateInput = styled(DatePicker)`
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
  min-width: 200px;
  width: 100%;
  justify-content: space-between;
`;

const SortLabel = styled(Typography)`
  margin-left: 5px;
  font-size: 14px;
  color: #555;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleLink = styled(MuiLink)`
  color: #1BA8F1;
  text-decoration: none;
  font-weight: bold;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const Stage = styled(Typography)`
  color: #555;
  font-size: 0.7rem !important;
  text-align: right;
`;

const AddressLink = styled(MuiLink)`
  display: block;
  color: #555;
  text-decoration: none;
  font-size: 0.7rem !important;
  margin-top: 5px;
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTableCell = styled(TableCell)`
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: 1px solid #ccc;

  &:last-child {
    border-right: none;
  }
`;

const TotalRowContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
`;

const TotalWidget = styled.div`
  flex: 1 1 calc(20% - 20px);
  max-width: calc(20% - 20px);
  min-width: 250px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const TotalValue = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
`;

const TotalLabel = styled(Typography)`
  font-size: 14px;
  color: #7f8c8d;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled(IconButton)`
  margin: 0 10px;
`;

const PageNumber = styled(Typography)`
  font-size: 16px;
  color: black;
`;

const ProjectsTable = ({ projects }) => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const ignoredColumns = ['_id', 'createdAt', 'updatedAt', '__v', 'Owner ID', 'Sales Rep ID', 'Project Manager ID', 'Last activity', 'Contract Signed Date', 'Number', 'Owner', 'Site Address', 'Stage', 'Owner Email', 'Sales Rep Email', 'Project Manager Email', 'Record ID'];

  useEffect(() => {
    const sanitizedProjects = projects.map(project => {
      if (project["Created at"]) {
        const truncatedDate = project["Created at"].substring(0, 19);
        project["Created at"] = truncatedDate;
      }
      return project;
    });

    setFilteredProjects(sanitizedProjects);
  }, [projects]);

  useEffect(() => {
    let result = [...projects];

    if (searchTerm) {
      result = result.filter(project =>
        project.Title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (startDate && endDate) {
      result = result.filter(project => {
        const createdAt = new Date(project["Created at"]);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    result = result.sort((a, b) => {
      const dateA = new Date(a["Created at"]);
      const dateB = new Date(b["Created at"]);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredProjects(result);
  }, [searchTerm, startDate, endDate, sortDirection, projects]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredProjects]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortClick = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const handleClearFilters = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
  };

  const calculateTotal = (key) => {
    return filteredProjects.reduce((total, project) => {
      const value = project[key];

      if (typeof value === 'string') {
        const sanitizedValue = parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0;
        return total + sanitizedValue;
      }

      if (typeof value === 'number') {
        return total + value;
      }

      return total;
    }, 0).toFixed(2);
  };

  const getUniqueKeys = (data) => {
    const allKeys = data.flatMap(Object.keys);
    const uniqueKeys = Array.from(new Set(allKeys)).filter(key => !ignoredColumns.includes(key));
    return uniqueKeys;
  };

  const tableKeys = getUniqueKeys(filteredProjects);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredProjects.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredProjects.length / recordsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const renderStage = (stage) => {
    if (stage.includes("Powered Up")) {
      return (
        <span>
          Powered Up <Bolt fontSize="0.7rem" />
        </span>
      );
    }
    return stage;
  };

  return (
    <Container>
      <FilterBar container spacing={2}>
        <FilterItem>
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search Title"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FilterItem>
        <FilterItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateInput
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FilterItem>
        <FilterItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateInput
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FilterItem>
        <IconContainer>
          <IconButton onClick={handleSortClick}>
            <Sort />
            <SortLabel>{sortDirection === 'asc' ? 'Asc' : 'Desc'}</SortLabel>
          </IconButton>
          <IconButton onClick={handleClearFilters}>
            <Clear />
          </IconButton>
        </IconContainer>
      </FilterBar>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableKeys.map(key => (
                <StyledTableCell key={key} style={{ borderRight: 'none', fontSize: '18px' }}>
                  {key}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRecords.map((project, index) => (
              <TableRow key={index}>
                {tableKeys.map((key, i) => (
                  <Tooltip key={i} title={project[key] || ''}>
                    {key === 'Title' ? (
                      <StyledTableCell style={{ width: '280px', minWidth: '280px', maxWidth: '280px' }}>
                        <TitleContainer>
                          <TitleLink
                            href={`https://app.coperniq.io/60/projects/${project["Record ID"]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {project.Title}
                          </TitleLink>
                          <Stage>{renderStage(project.Stage)}</Stage>
                        </TitleContainer>
                        <AddressLink
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(project["Site Address"])}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {project["Site Address"]}
                        </AddressLink>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>
                        {key.includes('Created at')
                          ? dayjs(project[key]).isValid()
                            ? dayjs(project[key]).format('YYYY-MM-DD')
                            : ''
                          : key === 'Gross Commission (Estimated)' || key === 'Net Commission' || key === 'Project value'
                          ? project[key]
                            ? parseFloat(project[key].replace(/[^0-9.-]+/g, ''))
                            : 0
          
                          : key === 'Primary phone' 
                          ? 
                            <MuiLink href={`tel:${project['Primary phone']}`} underline="none">
                              {project['Primary phone']}
                            </MuiLink>
                          : key === 'Primary email'
                          ? 
                            <MuiLink href={`mailto:${project['Primary email']}`} underline="none">
                              {project['Primary email']}
                            </MuiLink>
                          : project[key]|| null}
                      </StyledTableCell>
                    )}
                  </Tooltip>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationContainer>
        <PaginationButton onClick={prevPage} disabled={currentPage === 1}>
          <ArrowBack />
        </PaginationButton>
        <PageNumber>{`Page ${currentPage} of ${totalPages} (${filteredProjects.length} total records)`}</PageNumber>
        <PaginationButton onClick={nextPage} disabled={currentPage === totalPages}>
          <ArrowForward />
        </PaginationButton>
      </PaginationContainer>

      <TotalRowContainer>
        <TotalWidget>
          <TotalValue>${calculateTotal('Project value')}</TotalValue>
          <TotalLabel>Total Project Value</TotalLabel>
        </TotalWidget>
        <TotalWidget>
          <TotalValue>{calculateTotal('Project size')} kW</TotalValue>
          <TotalLabel>Total Project Size</TotalLabel>
        </TotalWidget>
        <TotalWidget>
          <TotalValue>${calculateTotal('Gross Commission (Estimated)')}</TotalValue>
          <TotalLabel>Total Gross Commission</TotalLabel>
        </TotalWidget>
        <TotalWidget>
          <TotalValue>${calculateTotal('Net Commission')}</TotalValue>
          <TotalLabel>Total Net Commission</TotalLabel>
        </TotalWidget>
      </TotalRowContainer>
    </Container>
  );
};

export default ProjectsTable;
